import React from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import CarIcon from "@material-ui/icons/DriveEta";

import { CloseButton, Panel, PanelHeader } from "../Panel";
import { FieldWrapper, LocalizedSelect, LocalizedTextField } from "../Input";
import { LocalizedButton } from "../Button";
import { LocalizedText } from "../LocalizedText";
import { SkeletonForm } from "../SkeletonForm";

import { VehicleFormData, VehicleFormViewProps } from "./interfaces";
import { vehicleToFormValues } from "./transformers";
import { useStyles } from "./styles";
import { convertUtcTimestamp } from "../../utils";

/**
 * Validation rules for the form
 */
const schema = yup.object({
  registration: yup.string().required("registration_required"),
  driver: yup.string().nullable(),
  description: yup.string().required("description_required"),
  cameras: yup.array().of(yup.string()),
});

/**
 * A form for creating or editing a vehicle
 */
export const VehicleFormView: React.FC<VehicleFormViewProps> = ({
  vehicle,
  cameras,
  loading,
  error,
  success,
  accountId,
  isSubmitting,
  onSubmit,
  isEdit,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [showSkeleton, setShowSkeleton] = React.useState(isEdit);

  const formik = useFormik<VehicleFormData>({
    initialValues: {
      registration: "",
      driver: "",
      description: "",
      cameras: [],
    },
    validateOnBlur: true,
    validationSchema: schema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  /**
   * Once vehicle has loaded, fill the form
   */
  React.useEffect(() => {
    if (!vehicle) return;

    formik.setValues(vehicleToFormValues(vehicle));

    setShowSkeleton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formik changes each render so cannot be declared as a dependency
  }, [vehicle]);

  /**
   * Construct header text
   */
  const headerText =
    loading || vehicle ? (
      loading ? (
        <LocalizedText text="loading" />
      ) : (
        <>
          <LocalizedText text="editing" />: {vehicle?.registration}
        </>
      )
    ) : (
      <LocalizedText text="create_vehicle" />
    );
  const header = (
    <PanelHeader icon={<CarIcon />} title={headerText}>
      <CloseButton
        onClick={() => history.push(`/accounts/summary/${accountId}/vehicles`)}
      />
    </PanelHeader>
  );

  return (
    <Panel header={header}>
      {error && (
        <Alert className={classes.alert} severity="error">
          <LocalizedText text="an_error_occurred" />
        </Alert>
      )}
      {success && (
        <Alert className={classes.alert} severity="info">
          <LocalizedText text="changes_saved" />
        </Alert>
      )}

      {showSkeleton ? (
        <SkeletonForm numFields={4} />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id="registration"
              name="registration"
              label="registration"
              value={formik.values.registration}
              onChange={formik.handleChange}
              error={
                formik.touched.registration &&
                Boolean(formik.errors.registration)
              }
              helperText={
                formik.touched.registration && formik.errors.registration
              }
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id="driver"
              name="driver"
              label="driver"
              value={formik.values.driver}
              onChange={formik.handleChange}
              error={formik.touched.driver && Boolean(formik.errors.driver)}
              helperText={formik.touched.driver && formik.errors.driver}
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id="description"
              name="description"
              label="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedSelect
              fullWidth
              id="cameras"
              name="cameras"
              label="cameras"
              value={formik.values.cameras}
              onChange={formik.handleChange}
              disabled={!cameras}
              options={
                cameras?.map((camera) => ({
                  value: camera.id,
                  label: camera.providerId,
                })) || []
              }
            ></LocalizedSelect>
          </FieldWrapper>

          <FieldWrapper>
            <LocalizedTextField
              fullWidth
              id="lastConnection"
              name="lastConnection"
              label="last_connection_time"
              value={convertUtcTimestamp(vehicle?.status?.lastConnection)}
              disabled
            />
          </FieldWrapper>

          <LocalizedButton fullWidth disabled={isSubmitting} type="submit">
            {isSubmitting ? "saving" : "save"}
          </LocalizedButton>
        </form>
      )}
    </Panel>
  );
};
